<template>
    <router-view/>
</template>

<script>

export default {
  name: 'SignatoriesIndex',
};
</script>
